import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "./http";

export const assistanceApi = createApi({
    reducerPath: "assistance",
    tagTypes: ["assistance"],
    baseQuery: axiosFetchBase("/assistance"),
    endpoints: (builder) => ({
        loginAsParticipant: builder.mutation({
            query: (data) => ({
                url: "/login",
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export const { useLoginAsParticipantMutation } = assistanceApi;
