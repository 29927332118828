import { createApi } from "@reduxjs/toolkit/query/react";
import http, { axiosFetchBase, getAuthConfig } from "./http";

const BASE_URL = "/transaction";

const createTransaction = (data, callback) => {
	http
		.post(`${BASE_URL}/create`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const deleteTransaction = (data, callback) => {
	http
		.post(`${BASE_URL}/delete`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const updateTransaction = (data, callback) => {
	http
		.post(`${BASE_URL}/update`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const refundTransaction = (data, callback) => {
	http
		.post(`${BASE_URL}/refund`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const moveTransactionProduct = (data, callback) => {
	http
		.post(`${BASE_URL}/move-transaction`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const updateFileTransaction = (data, callback) => {
	http
		.post(`${BASE_URL}/invoice-file-update`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const exportTransactionByTraining = (data, callback) => {
	http
		.post(`${BASE_URL}/export-by-training`, data, {
			responseType: "blob",
			...getAuthConfig(),
		})
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_TRANSACTION = {
	updateTransaction,
	deleteTransaction,
	refundTransaction,
	moveTransactionProduct,
	exportTransactionByTraining,
	updateFileTransaction,
	createTransaction,
};

export const transactionApi = createApi({
    reducerPath: "transactionApi",
    tagTypes: ["transaction"],
    baseQuery: axiosFetchBase("/transaction"),
    endpoints: (builder) => ({
        getTransactionByInvoiceNumber: builder.query({
            query: (data) => ({
                url: `/invoice/${data.invoiceNumber}`,
                method: "GET",
            }),
            providesTags: (_, __, arg) => [
                { type: "transaction", id: arg.id },
            ],
        }),
    }),
});

export const {
    useGetTransactionByInvoiceNumberQuery,
} = transactionApi;
