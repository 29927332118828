import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const ticketApi = createApi({
	reducerPath: "ticketApi",
	tagTypes: ["tickets"],
	baseQuery: axiosFetchBase("/ticket"),
	endpoints: (builder) => ({
        searchTickets: builder.query({
            query: (args) => ({
                url: "/search",
                method: "GET",
                query: {
                    search: args.search,
                }
            }),
            providesTags: [{ type: "tickets", id: "list" }],
        }),
	}),
});

export const {
    useSearchTicketsQuery,
} = ticketApi;
