import * as Sentry from "@sentry/react";
import axios from "axios";

window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.global = window;

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    enabled: import.meta.env.VITE_SENTRY_ENABLED === "true",
});
