import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";
import { CONSTANTS } from "@/utils";

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: axiosFetchBase("", ""),
    tagTypes: ["auth"],
    endpoints: (build) => ({
        login: build.mutation({
            query: (data) => ({
                url: "/login",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["auth"],
        }),
        getUser: build.query({
            query: () => ({
                url: "/api/user",
                method: "GET",
                headers: {
                    // TODO: we need to do this properly
                    Authorization: `Bearer ${localStorage.getItem(
                        CONSTANTS.AUTH_TOKEN,
                    )}`,
                },
            }),
            providesTags: ["auth"],
        }),
    }),
});

export const { useLoginMutation, useGetUserQuery } = authApi;
