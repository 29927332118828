import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
} from "redux-persist/es/constants";
import blogReducer from "@/redux/blog/blog.reducer.js";
import productReducer from "@/redux/product/product.reducer.js";
import memberReducer from "@/redux/member/member.reducer.js";
import transactionReducer from "@/redux/transaction/transaction.reducer.js";
import participantReducer from "@/redux/participant/participant.reducer.js";
import { changelogApi } from "@/services/changelog.service.js";
import { quizApi } from "@/services/quiz.service.js";
import { legalDocumentApi } from "@/services/legal-document.service.js";
import { broadcastCandidateApi } from "@/services/broadcast-candidate.service.js";
import { authApi } from "@/services/auth.service.js";
import { financeAccountApi } from "@/services/finance-account.service.js";
import { financeLedgerApi } from "@/services/finance-ledger.service.js";
import { participantApi } from "@/services";
import { financeJournalApi } from "@/services/finance-journal.service";
import { financeExpenseApi } from "@/services/finance-expense.service";
import { financeCustomerApi } from "@/services/finance-customer.service";
import { financeIncomeApi } from "@/services/finance-income.service";
import { assistanceApi } from "@/services/assistance.service";
import { voucherApi } from "@/services/voucher.service";
import { transactionApi } from "@/services/transaction.service";
import { financeSupplierApi } from "@/services/finance-supplier.service";
import { ticketApi } from "@/services/ticket.service";

const store = configureStore({
    reducer: persistReducer(
        {
            key: "root",
            storage,
            whitelist: "auth",
        },
        combineReducers({
            blog: blogReducer,
            product: productReducer,
            member: memberReducer,
            transaction: transactionReducer,
            participant: participantReducer,

            // RTK Queries
            [authApi.reducerPath]: authApi.reducer,
            [changelogApi.reducerPath]: changelogApi.reducer,
            [quizApi.reducerPath]: quizApi.reducer,
            [legalDocumentApi.reducerPath]: legalDocumentApi.reducer,
            [broadcastCandidateApi.reducerPath]: broadcastCandidateApi.reducer,
            [assistanceApi.reducerPath]: assistanceApi.reducer,
            [financeAccountApi.reducerPath]: financeAccountApi.reducer,
            [financeJournalApi.reducerPath]: financeJournalApi.reducer,
            [financeExpenseApi.reducerPath]: financeExpenseApi.reducer,
            [financeIncomeApi.reducerPath]: financeIncomeApi.reducer,
            [financeLedgerApi.reducerPath]: financeLedgerApi.reducer,
            [financeCustomerApi.reducerPath]: financeCustomerApi.reducer,
            [financeSupplierApi.reducerPath]: financeSupplierApi.reducer,
            [participantApi.reducerPath]: participantApi.reducer,
            [transactionApi.reducerPath]: transactionApi.reducer,
            [voucherApi.reducerPath]: voucherApi.reducer,
            [ticketApi.reducerPath]: ticketApi.reducer,
        }),
    ),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(
            thunk,
            authApi.middleware,
            changelogApi.middleware,
            quizApi.middleware,
            legalDocumentApi.middleware,
            broadcastCandidateApi.middleware,
            assistanceApi.middleware,
            financeAccountApi.middleware,
            financeJournalApi.middleware,
            financeLedgerApi.middleware,
            financeExpenseApi.middleware,
            financeIncomeApi.middleware,
            financeCustomerApi.middleware,
            financeSupplierApi.middleware,
            participantApi.middleware,
            transactionApi.middleware,
            voucherApi.middleware,
            ticketApi.middleware,
        ),
    devTools: true,
});
const persistor = persistStore(store);

export { store, persistor };
