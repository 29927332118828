import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const financeIncomeApi = createApi({
    reducerPath: "financeIncomeApi",
    tagTypes: ["finance-incomes"],
    baseQuery: axiosFetchBase("/finance-incomes"),
    endpoints: (builder) => ({
        getFinanceIncomes: builder.query({
            query: (data) => ({
                url: "/",
                method: "GET",
                query: {
                    filter: data.filter,
                    search: data.search,
                    page: data.page,
                    pageSize: data.pageSize,
                    startDate: data.startDate,
                    endDate: data.endDate,
                },
            }),
            providesTags: [
                {
                    type: "finance-incomes",
                    id: "list",
                },
            ],
        }),
        getFinanceIncomeById: builder.query({
            query: (data) => ({
                url: `/${data.id}`,
                method: "GET",
                initial: data.initial,
            }),
            providesTags: (_, __, arg) => [
                { type: "finance-incomes", id: arg.id },
            ],
        }),
        getFinanceIncomeReceiver: builder.query({
            query: (data) => ({
                url: "/receiver",
                method: "GET",
                query: {
                    search: data.search,
                    type: data.type,
                },
            }),
            providesTags: (_, __, arg) => [
                { type: "finance-income-receivers", id: arg.type },
            ],
        }),
        receiveFinanceIncome: builder.mutation({
            query: (data) => ({
                url: `/receive/${data.incomeId}`,
                method: "POST",
                body: {
                    id_account: data.accountId,
                    tgl_exec: data.date,
                },
            }),
            invalidatesTags: ["finance-incomes", "finance-journals"],
        }),
        addFinanceIncome: builder.mutation({
            query: (data) => ({
                url: "/",
                method: "POST",
                body: {
                    tgl_fintransaction: data.tgl_fintransaction,
                    id_receiver: data.id_receiver,
                    table_receiver: data.table_receiver,
                },
            }),
            invalidatesTags: ["finance-incomes"],
        }),
        addFinanceIncomeDetail: builder.mutation({
            query: (data) => ({
                url: "/detail",
                method: "POST",
                body: {
                    id_fintransaction: data.incomeId,
                    id_account: data.accountId,
                    value_fintransactiondetail: data.value,
                    name_form: data.nameForm,
                    comt_fintransactiondetail: data.comment,
                },
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-incomes", id: "list" },
                { type: "finance-incomes", id: arg.incomeId },
            ],
        }),
        updateFinanceIncome: builder.mutation({
            query: (data) => ({
                url: `/${data.incomeId}`,
                method: "PUT",
                body: {
                    tgl_fintransaction: data.tgl_fintransaction,
                    id_receiver: data.id_receiver,
                    table_receiver: data.table_receiver,
                },
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-incomes", id: "list" },
                { type: "finance-incomes", id: arg.incomeId },
            ],
        }),
        updateFinanceIncomeDetail: builder.mutation({
            query: (data) => ({
                url: `/detail/${data.id}`,
                method: "PUT",
                body: {
                    id_fintransaction: data.incomeId,
                    id_account: data.accountId,
                    value_fintransactiondetail: data.value,
                    name_form: data.nameForm,
                    comt_fintransactiondetail: data.comment,
                },
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-incomes", id: "list" },
                { type: "finance-incomes", id: arg.incomeId },
            ],
        }),
        deleteFinanceIncome: builder.mutation({
            query: (data) => ({
                url: `/${data.incomeId}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "finance-incomes", id: "list" }],
        }),
        deleteFinanceIncomeDetail: builder.mutation({
            query: (data) => ({
                url: `/detail/${data.detailId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-incomes", id: "list" },
                { type: "finance-incomes", id: arg.id },
            ],
        }),
        checkIsAllowedToReceiveFinanceIncome: builder.query({
            query: (data) => ({
                url: "/check/receive",
                method: "GET",
                query: {
                    amount: data.amount,
                },
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-income-receive-status", amount: arg.amount },
            ],
        }),
    }),
});

export const {
    useGetFinanceIncomesQuery,
    useGetFinanceIncomeByIdQuery,
    useGetFinanceIncomeReceiverQuery,
    useAddFinanceIncomeDetailMutation,
    useAddFinanceIncomeMutation,
    useReceiveFinanceIncomeMutation,
    useUpdateFinanceIncomeMutation,
    useUpdateFinanceIncomeDetailMutation,
    useDeleteFinanceIncomeMutation,
    useDeleteFinanceIncomeDetailMutation,
    useCheckIsAllowedToReceiveFinanceIncomeQuery,
} = financeIncomeApi;
