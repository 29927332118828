import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const financeCustomerApi = createApi({
    reducerPath: "financeCustomerApi",
    tagTypes: ["finance-customers"],
    baseQuery: axiosFetchBase("/finance-customers"),
    endpoints: (builder) => ({
        searchFinanceCustomers: builder.query({
            query: (args) => ({
                url: "/search",
                method: "GET",
                query: {
                    search: args.search,
                },
            }),
            providesTags: [{ type: "finance-customers", id: "list" }],
        }),
    }),
});

export const { useSearchFinanceCustomersQuery } = financeCustomerApi;
