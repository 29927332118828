import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const financeSupplierApi = createApi({
    reducerPath: "financeSupplierApi",
    tagTypes: ["finance-suppliers"],
    baseQuery: axiosFetchBase("/finance-suppliers"),
    endpoints: (builder) => ({
        getFinanceSuppliers: builder.query({
            query: (data) => ({
                url: `/${data.type}`,
                method: "GET",
                query: {
                    page: data.page,
                    limit: data.limit,
                    search: data.search,
                },
            }),
            providesTags: ["finance-suppliers"],
        }),
        createFinanceSupplier: builder.mutation({
            query: (data) => ({
                url: `/${data.type}`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["finance-suppliers"],
        }),
        updateFinanceSupplier: builder.mutation({
            query: (data) => ({
                url: `/${data.type}/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["finance-suppliers"],
        }),
        deleteFinanceSupplier: builder.mutation({
            query: (data) => ({
                url: `/${data.type}/${data.id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["finance-suppliers"],
        }),
    }),
});

export const {
    useGetFinanceSuppliersQuery,
    useCreateFinanceSupplierMutation,
    useUpdateFinanceSupplierMutation,
    useDeleteFinanceSupplierMutation,
} = financeSupplierApi;
