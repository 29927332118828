import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "./http";

export const voucherApi = createApi({
    reducerPath: "voucherApi",
    tagTypes: ["voucher"],
    baseQuery: axiosFetchBase("/voucher"),
    endpoints: (build) => ({
        listVoucher: build.query({
            query: (data) => ({
                url: "/list",
                method: "GET",
                query: {
                    search: data.search,
                    page: data.page,
                    pageSize: data.pageSize,
                },
            }),
            providesTags: [{ type: "voucher", id: "list" }],
        }),
        updateVoucher: build.mutation({
            query: (data) => ({
                url: "/update",
                method: "POST",
                body: data,
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "voucher", id: "list" },
                { type: "voucher", id: arg.id },
            ],
        }),
        createVoucher: build.mutation({
            query: (data) => ({
                url: "/create",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["voucher"],
        }),
        deleteVoucher: build.mutation({
            query: (data) => ({
                url: "/delete",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["voucher"],
        }),
        applyVoucher: build.mutation({
            query: (data) => ({
                url: "/apply",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["voucher"],
        }),
    }),
});

export const {
    useListVoucherQuery,
    useUpdateVoucherMutation,
    useCreateVoucherMutation,
    useDeleteVoucherMutation,
    useApplyVoucherMutation,
} = voucherApi;
