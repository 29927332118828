import { createApi } from "@reduxjs/toolkit/query/react";
import http, { axiosFetchBase, getAuthConfig } from "./http";

const BASE_URL = "/participant";

const updateParticipant = (data, callback) => {
    http.post(`${BASE_URL}/update`, data, getAuthConfig())
        .then((res) =>
            callback({ payload: res.data, error: null, success: true }),
        )
        .catch((error) =>
            callback({ payload: null, error: error.response, success: false }),
        );
};

const deleteParticipant = (data, callback) => {
    http.post(`${BASE_URL}/delete`, data, getAuthConfig())
        .then((res) =>
            callback({ payload: res.data, error: null, success: true }),
        )
        .catch((error) =>
            callback({ payload: null, error: error.response, success: false }),
        );
};

const uploadPhoto = (data, callback) => {
    http.post(`${BASE_URL}/photo/update`, data, getAuthConfig())
        .then((res) =>
            callback({ payload: res.data, error: null, success: true }),
        )
        .catch((error) =>
            callback({ payload: null, error: error.response, success: false }),
        );
};

const deletePhoto = (data, callback) => {
    http.post(`${BASE_URL}/photo/delete`, data, getAuthConfig())
        .then((res) =>
            callback({ payload: res.data, error: null, success: true }),
        )
        .catch((error) =>
            callback({ payload: null, error: error.response, success: false }),
        );
};

const exportByTraining = (data, callback) => {
    http.post(
        `${BASE_URL}/export-by-training`,
        data,
        { responseType: "blob" },
        getAuthConfig(),
    )
        .then((res) =>
            callback({ payload: res.data, error: null, success: true }),
        )
        .catch((error) =>
            callback({ payload: null, error: error.response, success: false }),
        );
};

const exportTableName = (data, callback) => {
    http.post(
        `${BASE_URL}/export-table-name`,
        data,
        { responseType: "blob" },
        getAuthConfig(),
    )
        .then((res) =>
            callback({ payload: res.data, error: null, success: true }),
        )
        .catch((error) =>
            callback({ payload: null, error: error.response, success: false }),
        );
};

const exportEnvelopeName = (data, callback) => {
    http.post(
        `${BASE_URL}/export-envelope-name`,
        data,
        { responseType: "blob" },
        getAuthConfig(),
    )
        .then((res) =>
            callback({ payload: res.data, error: null, success: true }),
        )
        .catch((error) =>
            callback({ payload: null, error: error.response, success: false }),
        );
};

const exportRatingTrainer = (data, callback) => {
    http.post(
        `${BASE_URL}/export-rating-trainer`,
        data,
        { responseType: "blob" },
        getAuthConfig(),
    )
        .then((res) =>
            callback({ payload: res.data, error: null, success: true }),
        )
        .catch((error) =>
            callback({ payload: null, error: error.response, success: false }),
        );
};

const exportKuesioner = (data, callback) => {
    http.post(
        `${BASE_URL}/export-kuesioner`,
        data,
        { responseType: "blob" },
        getAuthConfig(),
    )
        .then((res) =>
            callback({ payload: res.data, error: null, success: true }),
        )
        .catch((error) =>
            callback({ payload: null, error: error.response, success: false }),
        );
};

const exportKuesionerTrainer = (data, callback) => {
    http.post(`${BASE_URL}/export-kuesioner-trainer`, data, {
        responseType: "blob",
        ...getAuthConfig(),
    })
        .then((res) =>
            callback({ payload: res.data, error: null, success: true }),
        )
        .catch((error) =>
            callback({ payload: null, error: error.response, success: false }),
        );
};

const exportKuesionerLainnya = (data, callback) => {
    http.post(`${BASE_URL}/export-kuesioner-lainnya`, data, {
        responseType: "blob",
        ...getAuthConfig(),
    })
        .then((res) =>
            callback({ payload: res.data, error: null, success: true }),
        )
        .catch((error) =>
            callback({ payload: null, error: error.response, success: false }),
        );
};

const getListByUser = (data, callback) => {
    http.post(`${BASE_URL}/list-by-user`, data, getAuthConfig())
        .then((res) =>
            callback({ payload: res.data, error: null, success: true }),
        )
        .catch((error) =>
            callback({ payload: null, error: error.response, success: false }),
        );
};

export const SERVICE_PARTICIPANT = {
    uploadPhoto,
    deletePhoto,
    exportByTraining,
    exportTableName,
    exportEnvelopeName,
    exportRatingTrainer,
    exportKuesioner,
    exportKuesionerTrainer,
    exportKuesionerLainnya,
    updateParticipant,
    deleteParticipant,
    getListByUser,
};

export const participantApi = createApi({
    reducerPath: "participantApis",
    tagTypes: ["participants"],
    baseQuery: axiosFetchBase("/participant"),
    endpoints: (builder) => ({
        exportByTraining: builder.mutation({
            query: (args) => ({
                url: "/export-by-training",
                method: "POST",
                body: {
                    id_product: args.id_product,
                },
                responseType: "blob",
            }),
            invalidatesTags: ["participants"],
        }),
        exportTableName: builder.mutation({
            query: (args) => ({
                url: "/export-table-name",
                method: "POST",
                body: args.body,
                responseType: "blob",
            }),
            invalidatesTags: ["participants"],
        }),
        exportEnvelopeName: builder.mutation({
            query: (args) => ({
                url: "/export-envelope-name",
                method: "POST",
                body: args.body,
            }),
            invalidatesTags: ["participants"],
        }),
        exportRatingTrainer: builder.mutation({
            query: (args) => ({
                url: "/export-rating-trainer",
                method: "POST",
                body: args.body,
                responseType: "blob",
            }),
            invalidatesTags: ["participants"],
        }),
        exportKuesioner: builder.mutation({
            query: (args) => ({
                url: "/export-kuesioner",
                method: "POST",
                body: args.body,
                responseType: "blob",
            }),
            invalidatesTags: ["participants"],
        }),
        exportKuesionerTrainer: builder.mutation({
            query: (args) => ({
                url: "/export-kuesioner-trainer",
                method: "POST",
                body: args.body,
                responseType: "blob",
            }),
            invalidatesTags: ["participants"],
        }),
        exportKuesionerLainnya: builder.mutation({
            query: (args) => ({
                url: "/export-kuesioner-lainnya",
                method: "POST",
                body: args.body,
                responseType: "blob",
            }),
            invalidatesTags: ["participants"],
        }),
    }),
})

export const {
    useExportByTrainingMutation,
    useExportTableNameMutation,
    useExportEnvelopeNameMutation,
    useExportRatingTrainerMutation,
    useExportKuesionerMutation,
    useExportKuesionerTrainerMutation,
    useExportKuesionerLainnyaMutation,
} = participantApi;
