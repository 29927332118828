import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const financeLedgerApi = createApi({
    reducerPath: "financeLedgerApi",
    tagTypes: ["finance-ledgers"],
    baseQuery: axiosFetchBase("/finance-ledgers"),
    endpoints: (builder) => ({
        getFinanceLedgers: builder.query({
            query: (args) => ({
                url: "/",
                method: "GET",
                query: {

                },
            }),
            providesTags: [{ type: "finance-ledgers", id: "list" }],
        }),
        getFinanceLedgerById: builder.query({
            query: (data) => ({
                url: `/${data.id}`,
                method: "GET",
            }),
            providesTags: (_, __, arg) => [
                { type: "finance-ledgers", id: arg.id },
            ],
        }),
        createFinanceLedger: builder.mutation({
            query: (data) => ({
                url: "/",
                method: "POST",
                body: data,
            }),
            invalidatesTags: [{ type: "finance-ledgers", id: "list" }],
        }),
        updateFinanceLedger: builder.mutation({
            query: (data) => ({
                url: `/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-ledgers", id: "list" },
                { type: "finance-ledgers", id: arg.id },
            ],
        }),
        deleteFinanceLedger: builder.mutation({
            query: (data) => ({
                url: `/${data.id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-ledgers", id: "list" },
                { type: "finance-ledgers", id: arg.id },
            ],
        }),
    }),
});

export const {
    useGetFinanceLedgersQuery,
    useGetFinanceLedgerByIdQuery,
    useCreateFinanceLedgerMutation,
    useUpdateFinanceLedgerMutation,
    useDeleteFinanceLedgerMutation,
} = financeLedgerApi;
